<template>
  <div>
    <div class="about">
      <div class="title f-f-sans">
        <p>{{ $t("message.about-title") }}</p>
      </div>
      <div class="content">
        <p class="f-f-sans">
          {{ $t("message.about-full") }}
        </p>
      </div>
    </div>
    <Gallary />
  </div>
</template>

<script>
import Gallary from "./components/Gallary.vue";
export default {
  data: function () {
    return {
      content: "",
    };
  },

  components: {
    Gallary,
  },

  created() {
    this.content = require("../../assets/homepage/content.json");
  },
};
</script>

<style lang="scss" scoped>
@mixin styling($unit-size) {
  .about {
    padding: 106 * $unit-size 0 0 0;
    //height: 100vh;
    height: 100%;
    background-image: url("../../assets/imgs/home/about-us.png");
    background-color: rgba(201, 218, 234, 1);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    .title {
      p {
        text-align: center;
        font-size: 22 * $unit-size;
        line-height: 26 * $unit-size;
        font-weight: 700;
      }
    }
    .content {
      p {
        //background-color: red;
        white-space: pre-line;
        padding: 0 0 250 * $unit-size 0;
        margin-top: 27 * $unit-size;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
        line-height: 30 * $unit-size;
        width: 1166 * $unit-size;
        font-size: 16 * $unit-size;
      }
    }
  }
}
@media (max-width: 100vw) {
  @include styling($unit-size: 100vw/1366);
}
</style>